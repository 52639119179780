/*
Template Name: Minia - Admin & Dashboard Template
Author: Themesbrand
Version: 1.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/
@import "css/helpers/_mixins";

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "css/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/accordion";
@import "custom/components/avatar";
@import "custom/components/helper";
@import "custom/components/modals";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/toasts";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/alertify";
@import "custom/plugins/pristinejs";
@import "custom/plugins/choices";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "custom/pages/application";


//new css
@import "css/helpers/_reset";
@import "css/_common";
@import "css/_modificators";
@import "css/generated/_sprite-svg";
@import "css/components/_widgets";
@import "css/components/_wallets";
@import "css/components/card";
@import "css/components/_btn";
@import "css/components/_page";
@import "custom/components/cardwrapper";
@import "custom/plugins/formcheck";
@import "custom/plugins/modal";
@import "custom/plugins/input-group-text";
@import "custom/plugins/badges";
// @import "css/components/sidebar";
// @import "css/components/header.scss";
@import "css/components/slider.scss";
@import "custom/components/cryptocard.scss";
@import "custom/components/modalnavtabs";
@import "css/components/balances";
@import "custom/components/payment-methods";
@import "custom/components/showPassword";
@import "custom/components/customRadio";

//crypto pages
@import "css/pages/dashboard";
@import "custom/pages/crypto/dashboard";
@import "custom/pages/crypto/quickbuy";
@import "custom/pages/crypto/wallet.scss";
@import "custom/pages/crypto/exchange";
@import "custom/pages/crypto/profile";
@import "custom/pages/crypto/referral";
@import "custom/pages/crypto/twofa";

//forex pages
@import "custom/pages/forex/dashboard";
@import "custom/pages/forex/webtrader";

.float-wa-btn {
    height: 44px;
    width: 64px;
    position: fixed;
    bottom: 70px;
    right: 0;
    z-index: 1100;
}

.float-wa-btn a {
    color: rgb(23, 73, 77);
    height: 100%;
    width: 100%;
}